import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const AddDriver = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setServiceNumber] = useState("");
  const [password, setPassword] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [error, setError] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState("");

  const createDriver = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://lipabooking.vercel.app/api/v1/admin/createdriver",
        {
          username,
          email,
          phone,
          service,
          password,
          vehicleId: selectedVehicle,
        }
      );
      console.log(response.data);
      toast.success("Driver created successfully");
      updateVehicleStatus();
      setUsername('')
      setEmail('')
      setPhone('')
      setServiceNumber('')
      setPassword('')
      setSelectedVehicle('')
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error creating Driver");
    }
  };

  const updateVehicleStatus = async () => {
    try {
      const response = await axios.put(
        `https://lipabooking.vercel.app/api/v1/admin/updatevehicle/${selectedVehicle}`,
        {
          hasdriver: true, // Set hasdriver to true when a driver is assigned
        }
      );
      console.log(response.data);
      toast.success("Vehicle status updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error updating vehicle status");
    }
  };

  // get vehicles
  useEffect(() => {
    axios
      .get("https://lipabooking.vercel.app/api/v1/admin/getvehicles")
      .then((response) => {
        if (response) {
          const filtered = response.data.filter(
            (item) => item.hasdriver === "false"
          );
          console.log("unoccupied filter", filtered);
          if (filtered.length > 0) {
            console.log(filtered);
            setVehicles(filtered);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);
  return (
    <div className="w-full">
      <div className="flex md:flex-row flex-col w-full h-full justify-between items-start gap-10">
        {/* Profile Section */}
        <div className="bg-white md:w-[50%] w-full md:p-5 rounded-xl border border-slate-200 shadow shadow-slate-300 shadow-sm">
          <h2 className="text-xl font-semibold">Add Driver</h2>
          {/* profile edit */}

          <div className="flex flex-col space-y-4">
            <form action="" onSubmit={createDriver}>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Username
                </label>
                <input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  placeholder="username"
                  className="border border-slate-300 rounded-lg h-8 md:w-full w-full px-4 placeholder-slate-400 placeholder-text-xs"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Email
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="email"
                  className="border border-slate-300 rounded-lg h-8 md:w-full placeholder-slate-400 w-full px-4"
                />
              </div>
              
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Phone
                </label>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  placeholder="phone number"
                  className="border border-slate-300 placeholder-slate-400 rounded-lg h-8 md:w-full w-full px-4"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Service Number
                </label>
                <input
                  value={service}
                  onChange={(e) => setServiceNumber(e.target.value)}
                  type="text"
                  placeholder="service number"
                  className="border border-slate-300 placeholder-slate-400 rounded-lg h-8 md:w-full w-full px-4"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Password
                </label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="service number"
                  className="border border-slate-300 placeholder-slate-400 rounded-lg h-8 md:w-full w-full px-4"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Assign Vehicle
                </label>
                <select
                  id="destination"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={selectedVehicle}
                  onChange={(e) => setSelectedVehicle(e.target.value)}
                >
                  <option value="" disabled selected>
                    Choose Vehicle
                  </option>
                  {vehicles.map((car, index) => (
                    <option key={index} value={car._id}>
                      {car.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                  Add Driver
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* forgot password */}
      </div>
    </div>
  );
};

export default AddDriver;
