import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SettingsPage from "./pages/SettingsPage";
import NewUser from "./pages/NewUser";
import NewTrip from "./pages/NewTrip";
import NewDestination from "./pages/NewDestination";
import NewDriver from "./pages/NewDriver";
import NewVehicle from "./pages/NewVehicle";
import BookingsPage from "./pages/BookingsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterPage from "./pages/RegisterPage";
import PrivateRoutes from "./context/PrivateRoutes";

function App() {
  return (
    <div>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route index element={<HomePage />} />

          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/user" element={<NewUser />} />
          <Route path="/trip" element={<NewTrip />} />
          <Route path="/destination" element={<NewDestination />} />
          <Route path="/driver" element={<NewDriver />} />
          <Route path="/vehicle" element={<NewVehicle />} />
          <Route path="/bookings" element={<BookingsPage />} />
        </Route>

        {/* unprotected */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
