import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const AddDestination = () => {
  const [location, setLocation] = useState("");
  const [destinations, setDestinations] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const addDestination = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!location) {
      e.preventDefault();
      console.log("All fields are required");
      toast.error("Kindly fill all the inputs");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    // formData.append('file', file)

    try {
      const response = await axios.post(
        "https://lipabooking.vercel.app/api/v1/admin/createdestination",
        {
          location,
        }
      );
      // console.log(response);
      console.log("Location added successfully");
      toast.success("Location added successfully");
      setLoading(false);
      // Reset form fields after successful submission
      setLocation("");

      // setFile(null);
      // Optionally navigate to another page after successful submission
      // navigation('/dashboard');
    } catch (error) {
      console.log("Error adding new location");
      console.log(error);
      toast.error("Error adding location");
      setLoading(false);
    }
  };

  //get all destinations
  
  return (
    <div className="w-full">
      <div className="flex md:flex-row flex-col w-full h-full justify-between items-start gap-10">
        {/* Profile Section */}
        <div className="bg-white md:w-[50%] w-full md:p-5 rounded-xl border border-slate-200 shadow shadow-slate-300 shadow-sm">
          <h2 className="text-xl font-semibold">Add Destination</h2>
          {/* profile edit */}

          <div className="flex flex-col space-y-4">
            <form
              method="POST"
              onSubmit={addDestination}
              encType="multipart/form-data"
            >
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Enter Destination
                </label>
                <input
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  type="text"
                  placeholder="destination"
                  className="border border-slate-300 rounded-lg h-8 md:w-full w-full px-4 placeholder-slate-400 placeholder-text-xs"
                />
              </div>

              <div className="flex flex-col space-y-3 mt-3">
                {loading ? (
                  <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                    creating Destination
                  </button>
                ) : (
                  <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                    Add Destination
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>

        {/* forgot password */}
      </div>
    </div>
  );
};

export default AddDestination;
