import React, { useEffect, useState } from "react";
import { analytics } from "../constants";
import * as Icon from 'react-feather';
import axios from "axios";
import { toast } from "react-toastify";

const Analytics = () => {
  const [bookings,setBookings] = useState(0)
  const [users,setUsers] = useState(0)
  const [vehicles,setVehicles] = useState(0)
  const [drivers,setDrivers] = useState(0)

  const getAllBookings = async()=>{
    try {
      const response = await axios.get('https://lipabooking.vercel.app/api/v1/admin/allbookings');
      const data = response.data;
      // toast.success(data.length,"bookings collected")
      // return data.length
      setBookings(data.length)
      
    } catch (error) {
      console.error(error);
      // Handle error
      toast.error("Error fetching bookings");
      
    }
  }
  const getAllUsers = async()=>{
    try {
      const response = await axios.get('https://lipabooking.vercel.app/api/v1/admin/allusers');
      const data = response.data;
      // toast.success(data.length,"users collected")
      // return data.length
      setUsers(data.length)
      
    } catch (error) {
      console.error(error);
      // Handle error
      toast.error("Error fetching Users");
      
    }
  }
  const getAllDrivers = async()=>{
    try {
      const response = await axios.get('https://lipabooking.vercel.app/api/v1/admin/alldrivers');
      const data = response.data;
      // toast.success(data.length,"users collected")
      // return data.length
      setDrivers(data.length)
      
    } catch (error) {
      console.error(error);
      // Handle error
      toast.error("Error fetching Users");
      
    }
  }
  const getAllVehicles = async()=>{
    try {
      const response = await axios.get('https://lipabooking.vercel.app/api/v1/admin/getvehicles');
      const data = response.data;
      // toast.success(data.length,"bookings collected")
      // return data.length
      setVehicles(data.length)
      
    } catch (error) {
      console.error(error);
      // Handle error
      toast.error("Error fetching vehicles");
      
    }
  }

  useEffect(()=>{
    getAllBookings()
    getAllUsers()
    getAllVehicles()
    getAllDrivers()
  },[])
  return (
    <div className="w-full flex-1 flex md:flex-row flex-col justify-between items-center gap-4">
      {analytics.map((analytic) => {
        const IconComponent = Icon[analytic.icon]; // Access the icon component dynamically
        const value =
          analytic.function === "bookings"
            ? bookings
            : analytic.function === "users"
            ? users
            : analytic.function === "drivers"
            ? drivers
            : analytic.function === "vehicles"
            ? vehicles
            : 0; // Default case
        return (
          <div key={analytic.title} className="h-32 md:w-[50%] w-full border border-slate-300 rounded-md p-4 space-y-1">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-slate-800 text-sm tracking-wide">{analytic.title}</p>
              </div>
              <div>
                <p>
                  {IconComponent && <IconComponent size={24} color="black" />}
                </p>
              </div>
            </div>

            <div>
              <h3 className="font-bold font-poppins text-[30px]">{
              value
              }</h3>
            </div>
            <div>
              <p className="text-slate-400 font-poppin text-xs">{analytic.percentage}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Analytics;
