import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const TripTable = () => {
  const [tripdata, setTripdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);

  const getTrips = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://lipabooking.vercel.app/api/v1/admin/alltrips"
      );
      const data = response.data;
      setTripdata(data);
      setLoading(false);
      // console.log(response.data)
      // toast.success("data fetched");
    } catch (error) {
      console.log(error);
      toast.error("error getting products");
      setLoading(false);
    }
  };
  const getAllVehicles = async () => {
    try {
      const response = await axios.get(
        "https://lipabooking.vercel.app/api/v1/admin/getvehicles"
      );
      if (response) {
        setVehicles(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error fetching vehicles");
    }
  };

  //delete trip
  const deleteTrip = async (id) => {
    const result = await Swal.fire({
      title: "Do you really want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(
          `https://lipabooking.vercel.app/api/v1/admin/deletetrip/${id}`
        );
        toast.error("Product deleted");
        getTrips();
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getVehicleNameById = (vehicleId) => {
    const vehicle = vehicles.find((v) => v._id === vehicleId);
    return vehicle ? vehicle.name : "Unknown Vehicle";
  };

  useEffect(() => {
    getTrips();
    getAllVehicles()
  }, []);
  return (
    <div className="w-full">
      <div class="flex flex-col">
        <div class="-m-1.5 overflow-x-auto">
          <div class="p-1.5 min-w-full inline-block align-middle">
            <div class="overflow-hidden">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Vehicle_id
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Departure
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Destination
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Leaves_At
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Arrives_at
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                  {tripdata.map((trip) => {
                    return (
                      <tr>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">
                          {getVehicleNameById(trip.vehicleId)}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">
                          {formatDate(trip.tripdate)}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                          {trip.departure}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                          {trip.destination}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                          {trip.leavingTime}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                          {trip.arrivalTime}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                          <button onClick={()=>deleteTrip(trip._id)}
                            type="button"
                            class="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400"
                          >
                            Delete 
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripTable;
