import React, { useState } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import DashBoard from '../components/DashBoard'

const HomePage = () => {
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(!open);
  };
  return (
    <div className="flex-1 w-full h-screen overflow-hidden bg-primary overflow-y-scroll">
      
          

      <div className="flex sm:px-6 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          <Navbar open={open} setOpen={setOpen} show={show} />
        </div>
      </div>
      <div className="flex sm:px-6 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          <DashBoard open={open} setOpen={setOpen} show={show}/>
        </div>
      </div>
      
      
      
    </div>
  )
}

export default HomePage
