import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const AddUser = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  // const [file, setFile] = useState(null);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  // const navigation = useNavigate()

  const addUser = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!username || !email || !phone || !password) {
      e.preventDefault();
      console.log("All fields are required");
      toast.error("Kindly fill all the inputs");
      setLoading(false);
      return;
    }

    // const formData = new FormData();
    // formData.append('file', file)

    try {
      const response = await axios.post(
        "https://lipabooking.vercel.app/api/v1/user/createuser",
        {
          username,
          email,
          phone,
          password,
          address,
        }
      );
      // console.log(response);
      console.log("User added successfully");
      toast.success("User added successfully");
      setLoading(false);
      // Reset form fields after successful submission
      setUsername("");
      setEmail("");
      setPhone("");
      setPassword("");
      // setFile(null);
      // Optionally navigate to another page after successful submission
      // navigation('/dashboard');
    } catch (error) {
      console.log("Error adding new user");
      console.log(error);
      toast.error("Error adding user");
      setLoading(false);
    }
  };
  return (
    <div className="w-full">
      <div className="flex md:flex-row flex-col w-full h-full justify-between items-start gap-10">
        {/* Profile Section */}
        <div className="bg-white md:w-[50%] w-full md:p-5 rounded-xl border border-slate-200 shadow shadow-slate-300 shadow-sm">
          <h2 className="text-xl font-semibold">Add user</h2>
          {/* profile edit */}

          <div className="flex flex-col space-y-4">
            <form action="" onSubmit={addUser} encType="multipart/form-data">
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Username
                </label>
                <input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  placeholder="username"
                  className="border border-slate-300 rounded-lg h-8 md:w-full w-full px-4 placeholder-slate-400 placeholder-text-xs"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Email
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="email"
                  className="border border-slate-300 rounded-lg h-8 md:w-full placeholder-slate-400 w-full px-4"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Address
                </label>
                <input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  placeholder="address"
                  className="border border-slate-300 rounded-lg h-8 placeholder-slate-400 md:w-full w-full px-4"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Phone
                </label>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  placeholder="phone number"
                  className="border border-slate-300 placeholder-slate-400 rounded-lg h-8 md:w-full w-full px-4"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Password
                </label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="text"
                  placeholder="phone number"
                  className="border border-slate-300 placeholder-slate-400 rounded-lg h-8 md:w-full w-full px-4"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                {loading ? (
                  <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                    Creating New Userser....
                  </button>
                ) : (
                  <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                    Add User
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>

        {/* forgot password */}
      </div>
    </div>
  );
};

export default AddUser;
