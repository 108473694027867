import React from "react";
import StatsGraph from "../minicomponents/StatsGraph";

const Graph = () => {
  return (
    <div className="w-full h-full p-5">
      <div>
        <p className="font-semibold text-sm">Overview</p>
      </div>
      <div className="w-full h-full object-cover">
        <StatsGraph />
      </div>
    </div>
  );
};

export default Graph;
